import { render, staticRenderFns } from "./projectEvent.vue?vue&type=template&id=4b0cb988&scoped=true&"
import script from "./projectEvent.vue?vue&type=script&lang=js&"
export * from "./projectEvent.vue?vue&type=script&lang=js&"
import style0 from "./projectEvent.vue?vue&type=style&index=0&id=4b0cb988&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4b0cb988",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VSheet } from 'vuetify/lib/components/VSheet';
installComponents(component, {VBtn,VIcon,VSheet})
