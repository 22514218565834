<template>
  <tr
      @contextmenu="contextAction($event, entity)"
      :style="entity.is_completed ? `background-color : ${entity.is_completed ? (wsSUCCESS+'55') : 'transparent'};` : null "
      @mouseover="hover = true" @mouseleave="hover = false">

    <td width="10px" class="px-0">
      <div class="d-flex align-center">
        <v-sheet color="transparent" style="cursor:grab;" width="20" >
          <v-icon v-if="hover" class="mr-0 px-0">mdi-drag-vertical</v-icon>
        </v-sheet>
        <ws-check-box @input="editEvent" v-model="entity.is_completed" />
      </div>
    </td>
    <td>
      <h5 class="text-no-wrap">
        <v-icon :color="wsACCENT" > mdi-calendar</v-icon>
        {{ PARSE_TIME_STAMP(entity.date_created , true) }}
      </h5>
    </td>
    <td align="center">

      <div class="fill-height " style="position: relative">
          <v-sheet color="transparent" class="d-flex align-center fill-height">
             <textarea
                 class=""
                 v-model="entity.name"
                 @change="editEvent"
                 contenteditable="plaintext-only" :placeholder="$t('EnterTaskName')"
                 v-autogrow
                 rows="1"
                 style="outline: none; font-size: 14px; width: 400px; max-width: 400px; font-weight: 500 !important ; resize: none "
                 :style="
                `${ entity.is_completed ? `text-decoration : line-through;` : null }
                ${entity.priority === 'high' ? 'font-weight : 700' : null }
                `"
             />
          </v-sheet>

        <div v-if="hover"
             class="d-flex justify-center"
             style="position: absolute; pointer-events: none; width: 100% ; z-index: 1; margin-top: -10px"
        >
          <v-btn @click="$emit('addItem')" x-small style="pointer-events: auto; background-color: white" plain icon>
            <v-icon :color="wsATTENTION">mdi-plus-circle</v-icon>
          </v-btn>
        </div>

      </div>


    </td>
    <td>
      <ft-select
          @input="editEvent"
          v-model="entity.priority"
          :items="prioritySelect"
      >
        <div style="width: 100%">
          <h5 class="text-center">
            <v-icon :color="entity.priority === 'high' ? wsATTENTION : wsACCENT" v-if="entity.priority" >
              mdi-chevron-{{ entity.priority ==='high' ? 'up' : 'down' }}-box
            </v-icon>
            {{  !entity.priority ? $t('Priority') : entity.priority === 'high' ? $t('HighPriority') : $t('LowPriority')   }}
          </h5>
        </div>

      </ft-select>

    </td>
    <td>

      <ws-date-picker
          v-model="entity.date_scheduled"
          @input="editEvent"
          custom-activator
          time-stamp
      >
        <h5 class="text-no-wrap">
          <v-icon :color="wsACCENT" >mdi-calendar</v-icon>
          {{ PARSE_TIME_STAMP(entity.date_scheduled , false , true) }}
        </h5>
      </ws-date-picker>
    </td>
    <td>
      <ws-chip icon="mdi-account-tie" :text="entity.manager_name || entity.owner_name" />
    </td>
    <td>
      <ws-chip :color="wsACCENT" icon="mdi-file" :text="entity.files.length" />
    </td>

  </tr>
</template>

<script>
import {mapActions} from "vuex";

export default {
  name: "projectEvent",
  props : {
    entity : {
      type : Object,
      default() { return {} }
    },
    contextAction : {}
  },
  data() {
    return {
      hover : false,
    }
  },
  computed : {
    prioritySelect() {
      return [
        { text : this.$t('High') ,  value : 'high' },
        { text : this.$t('Standard') , value : null },
        { text : this.$t('Low') ,  value : 'low' }
      ]
    }
  },
  methods : {
    ...mapActions('adminCrmSystem' , [
        'ADMIN_CRM_EDIT_BUSINESS_EVENT'
    ]),
    async editEvent() {
      if ( this.entity.name ) {
        this.entity.name = this.entity.name.replace(/\n/gi, '<br>');
      }
      if ( this.entity.text ) {
        this.entity.text = this.entity.text.replace(/\n/gi, '<br>');
      }

      if (!await this.ADMIN_CRM_EDIT_BUSINESS_EVENT(this.entity)) {
        return this.notify(this.$t('Error'))
      }
      this.notify('Обʼєкт збережено')
    },
  },
  mounted() {
    if ( this.entity.name === 'Повідомлення про несправність') {
      this.entity.name = "=> Bug Report: " + this.entity.text.slice(0,40)
    }
  }

}
</script>

<style scoped>
.centered-input >>> input {
  text-align: center
}
</style>