<template>
  <sub-page
      v-model="$store.state.dashboard.pageData"
      :title="type === 'bug' ? $t('Bugs') : $t('Tickets')"
      :icon="type === 'bug' ? 'mdi-bug' : 'mdi-cog'"
  >
 
    <template #default>
      <ws-data-table
          v-if="items.length > 0"
          :items="itemsFiltered"
          :headers="eventHeaders"
          dense
          :context-actions-select="contextActionsSelect"
          @itemSelect="selectedItem = $event"
      >
        <template #item="{item , contextAction}">
          <project-event
              :context-action="contextAction"
              :entity="item"
              :key="item.uuid"  />
        </template>
      </ws-data-table>
    </template>

    <template #dialog>
      <wsDialog v-model="displayDialog"
                :title="$t('Event')"
                :show-save="false"
                :cancel-text="$t('Back')"
                width="900">

        <div class="d-flex justify-space-between">

          <div class="d-flex align-center ">
            <v-avatar class="mr-3" size="42" :color="getEventColor(selectedItem.type)">
              <v-icon dark> {{ getEventIcon(selectedItem.type) }} </v-icon>
            </v-avatar>
            <div>
              <h3 class="font-weight-medium">{{ selectedItem.name }}</h3>
              <h4 class="font-weight-light">{{ selectedItem.business_name }}</h4>
            </div>

          </div>

          <div class="mt-2">
            <div class="d-flex justify-end pointer">
              <ft-select @input="changeEvent(selectedItem,'is_completed',$event)" :items="CHOICE_COMPLETED">
                <v-chip dark  class="ml-3 pointer" :color="selectedItem.is_completed ? wsSUCCESSDARK : 'grey lighten-1'" >
                  <h5 class="pointer text-no-wrap" >
                    {{ selectedItem.is_completed  ? $t('Completed')  : $t('InProcess') }}
                  </h5>
                  <v-icon small>mdi-chevron-down</v-icon>
                </v-chip>
              </ft-select>
            </div>
          </div>

        </div>

        <v-sheet class="wsRoundedHalf pa-6 mt-9" outlined >

          <v-row>
            <v-col>
              <h5 class="mt-1" v-if="selectedItem.business_name"> {{ $t('Business') }}: <span class="font-weight-light ml-3">{{ selectedItem.business_name }}</span> </h5>
              <h5 class="mt-1 d-flex align-center "> {{ $t('Type') }}:
                <ft-select v-model="selectedItem.type"
                           @input="changeEvent(selectedItem,'type',$event)"
                           :items="eventTypesChange">

                  <template v-slot:default="{text}">
                    <span class="ml-3 font-weight-light">{{ text }}</span>
                    <v-icon small>mdi-chevron-down</v-icon>
                  </template>
                </ft-select>
              </h5>
            </v-col>
            <v-col >
              <div class="d-flex justify-end">
                <div>
                  <wsChip class="text-no-wrap" :color="wsACCENT" icon="mdi-account-tie" :text="selectedItem.owner_name"></wsChip>
                  <ws-chip  class="mt-1" centered :color="wsACCENT" icon="mdi-calendar" :text="MONTH_DAY(selectedItem.date_created)"></ws-chip>
                </div>
              </div>
            </v-col>
          </v-row>

          <v-row>
            <v-col>
              <h5 class="mb-3">{{$t('Manager')}}</h5>
              <wsCrmAdminManagersSelect  autocomplete @input="changeEvent(selectedItem,'manager_id',$event)" v-model="selectedItem.manager_id" />
            </v-col>
            <v-col>
              <h5 class="mb-3">{{$t('DateScheduled')}}</h5>
              <ws-date-picker @input="changeEvent(selectedItem,'date_scheduled',$event)" v-model="selectedItem.date_scheduled" ></ws-date-picker>
            </v-col>
          </v-row>

          <h5 class="mt-6"> {{ $t('Text') }}: </h5>
          <v-textarea v-model="selectedItem.text"
                      @change="changeEvent(selectedItem,'text',$event)"
                      style="font-size: 0.83em;"
                      row-height="1"
                      hide-details
                      :placeholder="$t('EnterText')"
                      single-line
                      auto-grow flat solo   />

          <h5 v-if="selectedItem.files.length > 0" class="mt-6 "> {{ $t('Files') }}: </h5>
          <div class="mt-6">
            <v-slide-x-transition mode="out-in">
              <v-simple-table v-if="!addFile"  dense>
                <tbody>
                <tr class="pointer" @click="openFile(item)" v-for="(item,j) in selectedItem.files" :key="'files_' + j">
                  <td style="border-bottom: none" width="10px"><v-icon :color="wsACCENT" small>mdi-file</v-icon></td>
                  <td style="border-bottom: none">{{ item.name }}</td>
                </tr>
                </tbody>
              </v-simple-table>
            </v-slide-x-transition>

            <v-btn :color="wsACCENT" @click="addFile = !addFile" block text small class="noCaps mt-3"> {{ !addFile ? $t('AddFile') : $t('Back') }} </v-btn>

          </div>

        </v-sheet>

      </wsDialog>
    </template>
  </sub-page>
</template>

<script>
import {mapActions} from "vuex";
import projectEvent from "@/components/pages/adminDashboard/tasks/projectEvent";
export default {
  name: "adminCrmBugs",
  components : {
    projectEvent
  },
  props : {
    type : {
      type : String,
      default : "task"
    }
  },
  data() {
    return {
      items : [],
      selectedItem : {
        files : []
      },
      displayDialog : false,
    }
  },
  computed : {
    itemsFiltered() {
      let items = this.items
      if ( !items || items.length === 0 ) {
        return []
      }
      if ( this.$store.state.adminCrmSystem.completionFilter === 'new' ) {
        items = items.filter(el => !el.is_completed)
      }
      if ( this.$store.state.adminCrmSystem.completionFilter === 'completed' ) {
        items = items.filter(el => el.is_completed)
      }
      return items
    },
    eventHeaders() {
      return [
        {   value : 'status' , width : "10" },
        {  text : this.$t('Date') , value : 'date_created'  },
        {  text : this.$t('Name') , value : 'name' , sort : false},
        {  text : this.$t('Priority') , value : 'priority' },
        {  text : this.$t('Deadline') , value : 'date_scheduled' },
        {  text : this.$t('Manager') , value : 'manager' },
        {   value : 'files' , width : "10" },
      ]
    },
    contextActionsSelect() {
      return [
        {
          text : this.$t('View') ,
          icon : 'mdi-eye',
          action : () => this.displayDialog = true
        },
        {
          text : this.$t('Delete') ,
          icon : 'mdi-delete',
          action : this.deleteItem
        }
      ]
    }
  },
  watch : {
    type() {
      this.initPage()
    }
  },
  methods : {
    ...mapActions('adminCrmSystem' , [
        'ADMIN_CRM_TASKS_GET_TYPE',
        'ADMIN_CRM_DELETE_BUSINESS_EVENT'
    ]),

    async deleteItem() {
      let result = await this.ADMIN_CRM_DELETE_BUSINESS_EVENT(this.selectedItem.uuid)
      if ( !result ) {
        return
      }
      let index = this.items.findIndex(el => el.uuid === this.selectedItem.uuid )
      if ( index === -1 ) {
        return
      }
      this.items.splice(index, 1)
      this.notify(this.$t('TaskDeleted'))
    },


    getEventIcon(type) {
      switch(type) {
        case 'note' : return 'mdi-notebook';
        case 'call' : return 'mdi-phone';
        case 'video_call' : return 'mdi-video';
        case 'task' : return 'mdi-checkbox-marked-outline';
        case 'file' : return 'mdi-file';
        case 'bug' : return 'mdi-bug';
        case 'registration' : return 'mdi-briefcase-outline'
        case 'status_change' : return 'mdi-cog';
        default : return 'mdi-cog';
      }
    },
    getEventColor(type) {
      switch(type) {
        case 'note' : return 'orange';
        case 'call' : return 'green lighten-1';
        case 'video_call' : return 'blue lighten-1';
        case 'task' : return 'teal lighten-1';
        case 'file' : return 'primary lighten-1';
        case 'bug' : return 'red lighten-2';
        case 'registration' : return 'green lighten-2';
        case 'status_change' : return 'purple lighten-1';
        default : return 'grey';
      }
    },

    async initPage() {
      this.$store.state.dashboard.pageData.icon = this.type === 'bug' ? 'mdi-bug' : 'mdi-cog'

      let result = await this.ADMIN_CRM_TASKS_GET_TYPE(this.type)
      if ( !result ) {
        return
      }
      this.items = result



    }

  },
  mounted() {
   this.initPage()
  }
}
</script>

<style scoped>

</style>